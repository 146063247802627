import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

	// Testimonials
	jQuery('.testimonials-slider').slick({
		dots: true,
		arrows: false,
		fade: true,
		adaptiveHeight: true,
	});

});